//
// billing-types.ts
//

import { Stripe } from "stripe";
import { BackendSuccessResponse } from "./backend-response-types";
import { CreateInvitationInput } from "./members-types";

/**
 * Enum for addons functionalities.
 */
export enum AddonsFunctionality {
  CLUSTER_NODE_COUNT = "Cluster",
  STORAGE_IN_GB = "Storage",
  VCPU = "vCPUs",
  RAM = "RAM",
  RETENTION_IN_HOURS = "Backups",
  SUPPORT = "Support",
  COLD_START = "Cold Start",
  EARLY_ACCESS = "Early Access",
  SLA = "SLA",
  REGIONS = "Regions",
  INSTANCE_SIZE = "Instance Size",
  CONNECTIONS = "Connections",
}

/**
 * Enum representing different types of addons that can be added to a subscription.
 *
 * Each addon type corresponds to a feature or service that a user can purchase or manage.
 */
export enum AddonLookupKey {
  SANDBOX_PROJECT = "sandbox_project",
  PRO_PROJECT = "pro_project",
  SCALE_PROJECT = "scale_project",
  MEMBER = "member",
  ORGANIZATION = "organization",
}

export type ProjectAddonLookUpKey = Exclude<
  AddonLookupKey,
  AddonLookupKey.MEMBER | AddonLookupKey.ORGANIZATION
>;

/**
 * Enum defining the possible actions that can be performed on an addon.
 *
 * - `CREATE`: Used when a new addon or resource is being provisioned.
 * - `UPGRADE`: Represents upgrading an existing addon to a higher tier or plan.
 * - `DOWNGRADE`: Represents downgrading an addon to a lower tier or plan.
 * - `DELETE`: Indicates the removal or deactivation of an existing addon.
 *
 * These action names are used in conjunction with `AddonLookupKey` to ensure
 * type safety and enforce valid operations on addons.
 */
export enum AddonActionNames {
  CREATE = "create",
  UPGRADE = "upgrade",
  DOWNGRADE = "downgrade",
  DELETE = "delete",
}

/**
 * Generic type representing an action performed on an addon.
 *
 * Each action consists of:
 * - `name`: A specific action type from `AddonActionNames`.
 * - `data`: Payload containing the necessary details for the action.
 *
 * @template T - Type of the data payload required for the action.
 * @template N - The specific action name from `AddonActionNames`.
 */
export type AddonAction<
  T = unknown,
  N extends AddonActionNames = AddonActionNames,
> = {
  name: N;
  data: T;
};

/**
 * Data structures defining the payload for specific addon actions.
 *
 * These interfaces ensure correct typing when performing operations on addons.
 */
export interface CreateProjectData {
  name: string;
  region: string;
  env: number;
  description?: string;
}

export interface UpgradeProjectData {
  projectId: string;
}

export interface DeleteProjectData {
  projectId: string;
}

export type DeleteMemberData =
  | { type: "current_member"; userId: string }
  | { type: "pending_member"; invitationId: string };

export interface DowngradeOrganizationData {}

export interface DeleteOrganizationData {}

/**
 * Defines the valid actions available for each `AddonLookupKey`.
 *
 * This mapping ensures that only the allowed actions can be performed on a given addon type.
 * Each key in `AddonActionMap` corresponds to an `AddonLookupKey`, and its value defines
 * the valid actions (`AddonActionNames`) along with their respective data payloads.
 */
export type AddonActionMap = {
  [AddonLookupKey.MEMBER]: {
    [AddonActionNames.CREATE]: AddonAction<
      CreateInvitationInput,
      AddonActionNames.CREATE
    >;
    [AddonActionNames.DELETE]: AddonAction<
      DeleteMemberData,
      AddonActionNames.DELETE
    >;
  };
  [AddonLookupKey.PRO_PROJECT]: {
    [AddonActionNames.CREATE]: AddonAction<
      CreateProjectData,
      AddonActionNames.CREATE
    >;
    [AddonActionNames.UPGRADE]: AddonAction<
      UpgradeProjectData,
      AddonActionNames.UPGRADE
    >;
    [AddonActionNames.DELETE]: AddonAction<
      DeleteProjectData,
      AddonActionNames.DELETE
    >;
  };
  [AddonLookupKey.SANDBOX_PROJECT]: {
    [AddonActionNames.CREATE]: AddonAction<
      CreateProjectData,
      AddonActionNames.CREATE
    >;
    [AddonActionNames.UPGRADE]: AddonAction<
      UpgradeProjectData,
      AddonActionNames.UPGRADE
    >;
    [AddonActionNames.DELETE]: AddonAction<
      DeleteProjectData,
      AddonActionNames.DELETE
    >;
  };
  [AddonLookupKey.SCALE_PROJECT]: {
    [AddonActionNames.CREATE]: AddonAction<
      CreateProjectData,
      AddonActionNames.CREATE
    >;
    [AddonActionNames.UPGRADE]: AddonAction<
      UpgradeProjectData,
      AddonActionNames.UPGRADE
    >;
    [AddonActionNames.DELETE]: AddonAction<
      DeleteProjectData,
      AddonActionNames.DELETE
    >;
  };
  [AddonLookupKey.ORGANIZATION]: {
    [AddonActionNames.DOWNGRADE]: AddonAction<
      DowngradeOrganizationData,
      AddonActionNames.DOWNGRADE
    >;
    [AddonActionNames.DELETE]: AddonAction<
      DeleteOrganizationData,
      AddonActionNames.DELETE
    >;
  };
};

/**
 * Extracts a discriminated union of possible actions for a given `AddonLookupKey`.
 *
 * This type maps each addon type to its corresponding allowed actions and data payloads.
 * It ensures that when handling addon actions, only the valid actions are allowed per addon type.
 *
 * ## Example:
 * If `K` is `AddonLookupKey.PROJECT`, the result will be a union of:
 * ```typescript
 * {
 *   name: AddonActionNames.CREATE;
 *   data: CreateProjectData;
 * } | {
 *   name: AddonActionNames.UPGRADE;
 *   data: UpgradeProjectData;
 * } | {
 *   name: AddonActionNames.DELETE;
 *   data: DeleteProjectData;
 * }
 * ```
 *
 * This allows TypeScript to enforce type safety and prevent invalid action assignments.
 */
export type ActionUnion<K extends AddonLookupKey> = {
  [P in keyof AddonActionMap[K]]: AddonActionMap[K][P] extends AddonAction<
    infer DataType,
    any
  >
    ? {
        name: P;
        data: DataType;
      }
    : never;
}[keyof AddonActionMap[K]];

/**
 * Represents an addon item with a dynamic action type based on its `AddonLookupKey`.
 *
 * Each addon operation includes:
 * - `addon`: Identifies the specific addon type.
 * - `quantity`: Specifies how many units of the addon are being requested (must be non-zero).
 * - `action`: Describes the operation to perform, including:
 *   - `name`: The action type (e.g., "create", "delete").
 *   - `data`: The parameters required for the operation, specific to the action type.
 *
 * ## Example Usage:
 * ```typescript
 * const addonItem: AddonItem = {
 *   addon: AddonLookupKey.PRO_PROJECT,
 *   quantity: 1,
 *   action: {
 *     name: AddonActionNames.CREATE,
 *     data: {
 *       name: "Project Alpha",
 *       region: "us-east-1",
 *       env: "production",
 *       description: "First project setup",
 *     },
 *   },
 * };
 * ```
 *
 * ## Validations:
 * - `quantity` must be a non-zero value.
 * - The `action` object must match the valid actions for the specified addon type.
 */
export type AddonItem = {
  [K in AddonLookupKey]: {
    addon: K;
    quantity: number;
    action: ActionUnion<K>;
  };
}[AddonLookupKey];

/**
 * Represents a subscription plan, its associated actions, default add-ons and UI details.
 *
 * - `lookupKey`: The unique identifier for the plan.
 * - `actions`: Defines actions to execute when the plan is activated.
 * - `defaultAddons`: Optional add-ons included by default, each with:
 *   - `addon`: The add-on identifier.
 *   - `quantity`: The number of add-ons included.
 *   - `action`: The actions associated with the add-on.
 * - `name` and `description`: Plan's display name and summary.
 * - `functionality`: Key features of the plan represented as key-value pairs,
 *      where the key is the functionality and the value is the numeric amount.
 * - `functionalityDetails`: Key features and their details, categorized by `AddonsFunctionality`.
 * - `additionalInfo`: Optional supplementary information.
 * - `monthlyPricing`: Pricing details for the plan, including:
 *   - `basePrice`: The base price of the plan.
 *   - `discountDetails`: Details of any discounts applied, including:
 *     - `discountedPrice`: The price after discount.
 *     - `discountReason`: The reason for the discount.
 */
export type AddonsDetails<K extends AddonLookupKey = AddonLookupKey> = {
  lookupKey: K;
  name: string;
  description: string;
  additionalInfo?: string;
  includedFunctionality: Partial<Record<AddonsFunctionality, number>>;
  functionalityDetails: Partial<
    Record<
      AddonsFunctionality,
      { summary?: string; fullDescription: string; tooltip?: string }
    >
  >;
  monthlyPricing: {
    basePrice: number;
    discountDetails?: {
      discountedPrice: number;
      discountReason: string;
    };
  };
};

/**
 * Represents the base data structure for managing a subscription flow for an organization.
 *
 * - `email`: The email address of the user initiating the subscription process.
 * - `organizationId`: (Optional) The unique identifier of the organization associated with the subscription.
 * - `userId`: (Optional) The unique identifier of the user performing the action.
 * - `successUrl`: (Optional) The URL to redirect the user to after successfully completing the subscription process.
 * - `cancelUrl`: (Optional) The URL to redirect the user to if the subscription process is canceled or fails.
 * - `returnUrl`: (Optional) The URL to return to after completing the subscription flow.
 */
export interface HandlingSubscriptionFlow {
  email: string;
  organizationId?: string;
  userId?: string;
  successUrl?: string;
  cancelUrl?: string;
  returnUrl?: string;
}

/**
 * Extends `HandlingSubscriptionFlow` for creating a subscription flow.
 *
 * This structure is used when creating a new subscription for an organization, requiring all
 * relevant details such as the organization ID, user ID, and plan details.
 *
 * - `successUrl`: The URL to redirect the user to after successfully completing the subscription creation process.
 * - `cancelUrl`: The URL to redirect the user to if the subscription creation process is canceled or fails.
 * - `plan`: The lookup key identifying the subscription plan to be created.
 * - `projectId`: The unique identifier of the project associated with the subscription.
 * - `timezoneOffsetSeconds`: The time offset in seconds from UTC, used for time-sensitive operations
 *   to account for the user's local timezone.
 * - `returnUrl`: This field is not applicable in the creation flow and is excluded.
 */
export interface HandlingCreateSubscriptionFlow
  extends Omit<HandlingSubscriptionFlow, "returnUrl"> {
  successUrl: string;
  organizationId: string;
  userId: string;
  cancelUrl: string;
  newAddons: AddonItem[];
  projectId: string;
  timezoneOffsetSeconds: number;
}

/**
 * Extends `HandlingSubscriptionFlow` for updating a subscription flow.
 *
 * This structure is used when updating an existing subscription, requiring the organization ID, user ID,
 * and the add-ons to be added. Cancelation is not applicable for update flows.
 *
 * - `successUrl`: The URL to redirect the user to after successfully completing the subscription update process.
 * - `cancelUrl`: Must not be provided in update flows as cancelation is not applicable.
 * - `newAddons`: `AddonItem` objects representing additional feature or services to add to the subscription.
 * - `returnUrl`: This field is not applicable in the update flow and is excluded.
 */
export interface HandlingUpdateSubscriptionFlow
  extends Omit<
    HandlingSubscriptionFlow,
    "organizationId" | "userId" | "successUrl" | "cancelUrl"
  > {
  successUrl: string;
  cancelUrl: string;
  organizationId: string;
  userId: string;
  newAddons: AddonItem[];
}

/**
 * Extends `HandlingSubscriptionFlow` for viewing a subscription flow.
 *
 * This structure is used when displaying subscription details or confirmation pages. The `returnUrl`
 * field becomes mandatory to define the redirection after completing the viewing process.
 *
 * - `returnUrl`: The URL to return to after completing the subscription view process.
 * - Other fields (`organizationId`, `userId`, `successUrl`, `cancelUrl`) are not applicable for viewing flows.
 */
export interface HandlingViewSubscriptionFlow
  extends Omit<HandlingSubscriptionFlow, "returnUrl" | "organizationId"> {
  returnUrl: string;
  organizationId: string;
}

/**
 * Represents the data structure required to handle a subscription cancellation flow.
 *
 * This interface extends the base `HandlingSubscriptionFlow` but excludes certain optional fields
 * (`organizationId`, `userId`, `successUrl`, `cancelUrl`, and `projectId`) and redefines them as mandatory.
 *
 * ## Fields
 * - `successUrl` (string): The URL to redirect the user to after successfully canceling the subscription.
 * - `cancelUrl` (string): The URL to redirect the user to if the subscription cancellation is aborted or fails.
 * - `organizationId` (string): The unique identifier of the organization associated with the subscription to be canceled.
 * - `userId` (string): The unique identifier of the user initiating the cancellation process.
 * - `projectId` (string): The list of project ids to be removed after subscription is being canceled.
 *
 * ## Usage
 * This interface ensures all required parameters for the cancellation flow
 * are provided and is primarily used in subscription cancellation API routes.
 */
export interface HandlingCancelSubscriptionFlow
  extends Omit<
    HandlingSubscriptionFlow,
    "organizationId" | "userId" | "successUrl" | "cancelUrl" | "projectId"
  > {
  successUrl: string;
  cancelUrl: string;
  organizationId: string;
  deleteOrganization: boolean;
  userId: string;
}

/**
 * Represents the successful response of a subscription creation process.
 *
 * This interface defines the structure of the response returned when a subscription
 * is successfully created and a Stripe Checkout session is generated.
 *
 * ## Properties:
 * - `stripeSession`: The Stripe Checkout session object that includes detailed
 *   information about the created session, such as its ID, status, and URLs.
 * - `redirectUrl`: A URL to redirect the user to complete the payment process
 *   in the Stripe Checkout interface.
 *
 */
export interface CreateSubscriptionSuccessResponse {
  stripeSession: Stripe.Response<Stripe.Checkout.Session>;
  redirectUrl: Stripe.Checkout.Session["url"];
}

/**
 * Represents the response from a successful subscription update operation.
 *
 * - `status`: Indicates the current state of the subscription update process.
 *   - `"completed"`: The subscription upgrade was successfully completed with a successful payment.
 *   - `"pending"`: The subscription update process is incomplete, and an invoice needs to be paid.
 *     In this state, the `redirectUrl` will point to the Stripe-hosted invoice page for payment.
 * - `redirectUrl`: The URL to redirect the user based on the status:
 *   - If `status` is `"completed"`, this could be a confirmation page or the application's subscription management page.
 *   - If `status` is `"pending"`, this will be the Stripe-hosted invoice page for the user to complete the payment.
 */
export interface UpdateSubscriptionSuccessResponse {
  status: "completed" | "pending";
  redirectUrl: string;
}

/**
 * Represents the response returned upon successful deletion of a subscription.
 *
 * ## Fields
 * - `redirectUrl` (string): The URL to redirect the user to after the subscription has been successfully canceled.
 *
 * ## Usage
 * This interface is used to define the structure of the success response for API routes
 * handling subscription cancellations. It provides a redirection path for the user
 * to confirm the success of the operation or guide them to the next action.
 */
export interface CancelSubscriptionSuccessResponse {
  redirectUrl: string;
}

/**
 * Represents the response for successfully handling a view subscription flow.
 *
 * - `redirectUrl`: The URL to which the user should be redirected after
 *   successfully completing the view subscription process.
 *
 */
export interface ViewSubscriptionSuccessResponse {
  redirectUrl: string;
}

/**
 * Defines a line item used in Stripe Checkout sessions.
 *
 * - `price`: The ID of the price object in Stripe.
 * - `quantity`: The quantity of the item being purchased.
 *
 * @example
 * const lineItem: LineItem = {
 *   price: 'price_12345',
 *   quantity: 2,
 * };
 */
export type LineItem = Stripe.Checkout.SessionCreateParams.LineItem;

/**
 * Represents the possible states in the lifecycle of a subscription-related operation.
 *
 * ## States
 * - `draft`: The operation is in its initial state, with no associated Stripe invoice.
 *   This indicates the operation has been created but not yet moved to billing or further processing.
 * - `pending`: The operation has an associated Stripe invoice that is not yet paid.
 *   This indicates that the operation is awaiting payment before further action can occur.
 * - `ready`: The operation is fully prepared for processing.
 *   This indicates that the Stripe invoice is finalized and paid, and the operation can proceed.
 * - `processed`: The operation has been successfully processed by the backend.
 *   This state indicates that all associated actions have been completed or are in progress.
 * - `cancelled`: The operation has been explicitly cancelled and will not proceed further.
 *   This can occur due to user action, subscription cancellation, or other conditions.
 *
 * ## Usage
 * The `OperationStatus` type provides a standardized framework for tracking the lifecycle of
 * subscription-related operations. It ensures clear workflows for managing and processing
 * operations in both frontend and backend systems.
 */
export type OperationStatus =
  | "draft"
  | "pending"
  | "ready"
  | "processed"
  | "cancelled";

/**
 * Enum representing the possible reasons for subscription or operation cancellations.
 *
 * Each value corresponds to a specific scenario where a cancellation may occur,
 * providing clear and consistent identifiers for cancellation reasons in the system.
 *
 * ## Enum Values
 * - `STRIPE_SESSION_NULL`: Indicates that the Stripe session is null or invalid.
 * - `BILLING_SUBSCRIPTION_POST_ENDPOINT`: Cancellation triggered by the failure of the POST endpoint
 *   when creating a subscription in the billing system.
 * - `BILLING_SUBSCRIPTION_PATCH_ENDPOINT`: Cancellation triggered by the failure of the PATCH endpoint
 *   when updating a subscription in the billing system.
 * - `BILLING_SUBSCRIPTION_DELETE_ENDPOINT`: Cancellation triggered by the failure of the DELETE endpoint
 *   when deleting a subscription in the billing system.
 * - `BILLING_SUBSCRIPTION_ENDPOINT_FAILED`: General failure in any billing subscription endpoint
 *   that resulted in cancellation.
 * - `SUBSCRIPTION_DELETE_EVENT`: Cancellation triggered by a Stripe `subscription.deleted` event.
 *
 * ## Purpose
 * This enum provides a standardized way to document and handle the various reasons for cancellations,
 * enabling easier debugging, logging, and user notifications.
 */
export enum CancelReason {
  STRIPE_SESSION_NULL = "STRIPE_SESSION_NULL",
  BILLING_SUBSCRIPTION_POST_ENDPOINT = "BILLING_SUBSCRIPTION_POST_ENDPOINT",
  BILLING_SUBSCRIPTION_PATCH_ENDPOINT = "BILLING_SUBSCRIPTION_PATCH_ENDPOINT",
  BILLING_SUBSCRIPTION_DELETE_ENDPOINT = "BILLING_SUBSCRIPTION_DELETE_ENDPOINT",
  BILLING_SUBSCRIPTION_ENDPOINT_FAILED = "BILLING_SUBSCRIPTION_ENDPOINT_FAILED",
  SUBSCRIPTION_DELETE_EVENT = "SUBSCRIPTION_DELETE_EVENT",
  VOIDED_INVOICE = "VOIDED_INVOICE",
}

/**
 * Base structure for all operations, containing common fields shared across all statuses.
 * - `user_id`: The ID of the user who initiated the operation.
 * - `created_at`: The timestamp when the operation was created.
 * - `updated_at`: The timestamp when the operation was last updated.
 * - `addonItem`: Details about the add-ons included in the operation, specifying the type and actions.
 */
export type OperationBase = {
  user_id: string;
  created_at: string;
  updated_at: string;
  addonItem: AddonItem;
};

/**
 * Represents an operation in the "draft" state.
 * - `status`: Always "draft".
 * - `invoice_id`: Not allowed in draft operations.
 * - `cancelled` and `processed`: Not applicable to draft operations.
 */
export type DraftOperation = OperationBase & {
  status: "draft";
  invoice_id?: never; // `invoice_id` is not allowed for draft operations
  cancelled?: never; // `cancelled` is not applicable
  processed?: never; // `processed` is not applicable
};

/**
 * Represents an operation in the "pending" state.
 * - `status`: Always "pending".
 * - `invoice_id`: Mandatory for pending operations.
 * - `cancelled` and `processed`: Not applicable to pending operations.
 */
export type PendingOperation = OperationBase & {
  status: "pending";
  invoice_id: string; // `invoice_id` is mandatory for pending operations
  cancelled?: never; // `cancelled` is not applicable
  processed?: never; // `processed` is not applicable
};

/**
 * Represents an operation in the "ready" state.
 * - `status`: Always "ready".
 * - `invoice_id`: Mandatory for ready operations.
 * - `cancelled` and `processed`: Not applicable to ready operations.
 */
export type ReadyOperation = OperationBase & {
  status: "ready";
  invoice_id: string; // `invoice_id` is mandatory for ready operations
  cancelled?: never; // `cancelled` is not applicable
  processed?: never; // `processed` is not applicable
};

/**
 * Represents an operation in the "cancelled" state.
 * - `status`: Always "cancelled".
 * - `invoice_id`: Optional; may be present or absent for cancelled operations.
 * - `cancelled`: Mandatory field containing details about the cancellation.
 * - `processed`: Not applicable to cancelled operations.
 */
export type CancelledOperation = OperationBase & {
  status: "cancelled";
  invoice_id?: string; // `invoice_id` is optional for cancelled operations
  cancelled: {
    cancelled_at: string; // Timestamp when the operation was cancelled
    reason: CancelReason; // Reason for the cancellation
    info?: string; // Additional information about the cancellation (optional)
  };
  processed?: never; // `processed` is not applicable
};

/**
 * Represents an operation in the "processed" state.
 * - `status`: Always "processed".
 * - `invoice_id`: Mandatory for processed operations.
 * - `processed`: Mandatory field containing details about the processing result.
 * - `cancelled`: Not applicable to processed operations.
 */
export type ProcessedOperation = OperationBase & {
  status: "processed";
  invoice_id: string; // `invoice_id` is mandatory for processed operations
  cancelled?: never; // `cancelled` is not applicable
  processed: {
    processed_at: string; // Timestamp when the operation was processed
    result: BackendSuccessResponse<any>; // Backend result of the operation processing
  };
};

/**
 * Represents the combined type for operations in all possible states.
 * The specific type depends on the `status` field:
 * - `"draft"`: DraftOperation
 * - `"pending"` or `"ready"`: PendingReadyOperation
 * - `"cancelled"`: CancelledOperation
 * - `"processed"`: ProcessedOperation
 */
export type Operation =
  | DraftOperation
  | PendingOperation
  | ReadyOperation
  | CancelledOperation
  | ProcessedOperation;

/**
 * Represents a collection of subscription-related operations, indexed by their unique operation IDs (`op_id`).
 *
 * Each operation corresponds to a specific action within the subscription system, such as a plan upgrade
 * or the addition of an add-on. The operations progress through defined statuses (`OperationStatus`),
 * providing a clear workflow for handling and processing subscription-related tasks.
 */
export interface Operations {
  [op_id: string]: Operation;
}

/**
 * The props for the `SubscriptionStatusBanner` and `SubscriptionStatusAlertDialog` component.
 *
 * @property {string} title - The title.
 * @property {string} description - The description displayed below the title.
 * @property {string} actionButtonText - The text for the action button on the alert.
 */
export type SubscriptionStatusAlertProps = {
  title: string;
  description: string;
  actionButtonText: string;
};

/**
 * Maps each Stripe event type to its corresponding `event.data.object` type.
 *
 * This type is used to associate specific Stripe event types with their expected resource objects,
 * ensuring proper type inference and validation when handling these events.
 *
 * ### Keys:
 * - Stripe event types that the application handles (e.g., `"invoice.finalized"`, `"customer.subscription.updated"`).
 *
 * ### Values:
 * - The type of the `event.data.object` for the corresponding Stripe event (e.g., `Stripe.Invoice`, `Stripe.Subscription`).
 */
export type StripeEventDataMap = {
  "invoice.finalized": Stripe.Invoice;
  "invoice.paid": Stripe.Invoice;
  "invoice.voided": Stripe.Invoice;
  "customer.subscription.created": Stripe.Subscription;
  "customer.subscription.updated": Stripe.Subscription;
  "customer.subscription.deleted": Stripe.Subscription;
  "customer.subscription.pending_update_expired": Stripe.Subscription;
  "customer.created": Stripe.Customer;
  "customer.updated": Stripe.Customer;
  "invoice.created": Stripe.Invoice;
  "invoice.updated": Stripe.Invoice;
  "invoice.deleted": Stripe.Invoice;
};

/**
 * Extracts the subset of Stripe event types handled by the application.
 *
 * This type represents the keys of the `StripeEventDataMap`, restricting the event types to only
 * those explicitly defined as being handled by the application.
 */
export type StripeEventSubset = keyof StripeEventDataMap;

/**
 * Extends the `Stripe.Event` type to create a discriminated union for handled events.
 *
 * This type maps each Stripe event type to a more specific structure, including its exact `type`
 * and the expected shape of the `event.data.object`. It ensures precise type inference for each
 * handled Stripe event, reducing ambiguity and improving code reliability.
 *
 * ### Structure:
 * - `type`: The specific Stripe event type (e.g., `"invoice.finalized"`, `"customer.subscription.updated"`).
 * - `data.object`: The associated resource object for the event (e.g., `Stripe.Invoice`, `Stripe.Subscription`).
 *
 * ### Usage:
 * This type is used to define the shape of events being processed by the application, enabling
 * type-safe handling of Stripe webhooks.
 */
export type HandledStripeEvent = {
  [K in StripeEventSubset]: Omit<Stripe.Event, "type" | "data"> & {
    type: K; // Specific event type.
    data: {
      object: StripeEventDataMap[K]; // Associated resource type.
    };
  };
}[StripeEventSubset];

/**
 * Represents metadata associated with a subscription, including details about
 * the organization, user, and project relevant to subscription actions.
 *
 * Properties:
 * - `organization_id`: The unique identifier of the organization linked to the subscription.
 * - `user_email`: The email address of the user associated with the subscription.
 * - `user_id`: The unique identifier of the user associated with the subscription.
 */
export interface SubscriptionMetadata {
  organization_id: string;
  user_email: string;
  user_id: string;
}
