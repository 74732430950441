//
// useGetDatabaseTableMetadata.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { useGetFetchConditionBasedOnProject } from "@custom-hooks/fetch-data/hooks/useGetFetchConditionBasedOnProject";
import { DatabaseTableMetadata } from "@data-types/databases-types";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { ProjectStatus } from "@data-types/projects-types";
import { tableNameExist } from "@lib/client-side";
import { databaseNameExist } from "../utils/databaseNameExist";
import { useGetDatabaseTables } from "./useGetDatabaseTables";
import { useGetProjectDatabases } from "./useGetProjectDatabases";

/**
 * Custom hook to fetch metadata for a specific table within a database.
 *
 * @param {string} tableName - The name of the table for which the metadata is being fetched.
 * @param {string} databaseName - The name of the database containing the table.
 * @param {string} projectId - The ID of the project containing the database.
 *
 * @returns {FetchHookResult<DatabaseTableMetadata>} The result object containing the fetched table metadata.
 */
export function useGetDatabaseTableMetadata(
  tableName: string,
  databaseName: string,
  projectId: string
): FetchHookResult<DatabaseTableMetadata> {
  const fetchCondition = useGetFetchConditionBasedOnProject({
    id: projectId,
    projectStatus: ProjectStatus.RUNNING_AND_WORKING,
  });

  const { data: databases } = useGetProjectDatabases(projectId);
  const { data: tables } = useGetDatabaseTables(databaseName, projectId);
  const doesDatabaseNameExist = databaseNameExist(
    databaseName,
    databases ?? []
  );
  const doesTableNameExist = tableNameExist(tableName, tables ?? []);

  return useFetchData<DatabaseTableMetadata>({
    fetchCondition:
      fetchCondition &&
      projectId !== "" &&
      databaseName !== "" &&
      tableName !== "" &&
      doesDatabaseNameExist &&
      doesTableNameExist,
    source: "backend",
    swrKey: {
      url: `/api/projects/${projectId}/databases/${databaseName}/table/${tableName}/metadata`,
      component: "useGetDatabaseTableMetadata",
    },
    options: {
      revalidateOnFocus: false,
    },
  });
}
