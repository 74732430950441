/**
 * Converts a metadata key status number to an object containing a tooltip message and a color based on the theme.
 *
 * @param {number} status - The status code of the metadata key.
 * @param {any} theme - The theme object containing color palette information.
 * @returns {{
 *   tooltip: string;
 *   color: string;
 * }} An object representing the tooltip message and color for the metadata key status.
 */
export function convertMetadataKeyStatus(
  status: number,
  theme: any
): { tooltip: string; color: string } {
  let primaryKeyStatus: { tooltip: string; color: string };

  switch (status) {
    case 0:
      primaryKeyStatus = {
        tooltip: "",
        color: theme.palette.neutral.lightGrey,
      };
      break;
    case 1:
      primaryKeyStatus = {
        tooltip: "Primary Key.",
        color: theme.palette.success.main,
      };
      break;
    default:
      primaryKeyStatus = {
        tooltip: "",
        color: theme.palette.neutral.lightGrey,
      };
      break;
  }

  return primaryKeyStatus;
}
