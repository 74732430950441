import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import {
  HandlingViewSubscriptionFlow,
  ViewSubscriptionSuccessResponse,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { getBaseUrl } from "@lib/client-side";

/**
 * Custom hook to manage the subscription view logic.
 *
 * @param {EditDataOpt<ViewSubscriptionSuccessResponse>} options - Configuration options for `useEditData_v2`.
 *
 * @returns {object} An object containing:
 *   - `viewSubscription` (function): A function to initiate the subscription view process.
 *       - Returns:
 *         - `Promise<ViewSubscriptionSuccessResponse>`: The result of the subscription view operation.
 *   - `isLoading` (boolean): Indicates whether the subscription view operation or associated mutation is in progress.
 *   - `error` (ClientError | null): The error returned by the useEditData_v2, if any.
 */
export const useViewSubscription = (
  options: EditDataOpt<ViewSubscriptionSuccessResponse>
) => {
  const { data: session } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();
  const email = organizationId
    ? session?.authorizations?.organizations[organizationId]?.ownerEmail
    : undefined;

  const { isLoading, isMutatingData, editData, error } =
    useEditData_v2<ViewSubscriptionSuccessResponse>({
      ...options,
      successCallback: (data) => {
        options.successCallback?.(data);

        const stripeRedirectUrl = data.value.redirectUrl;
        if (stripeRedirectUrl) {
          window.location.href = stripeRedirectUrl;
        } else {
          console.error("Stripe redirect URL is not available.");
        }
      },
      stopGlobalError: options?.stopGlobalError ?? false,
      customErrorDialog: {
        title: "Failed to load subscription details",
        message:
          "We couldn't retrieve your subscription details at the moment. Please try again later. If the issue persists, contact us for assistance.",
      },
    });

  const viewSubscription = async () => {
    const viewSubscriptionResponse = await editData({
      method: "PATCH",
      endpoint: `/api/billing/portal/${email}`,
      endpointCallLocation: "viewSubscription.js",
      serverType: "dashboard",
      body: {
        organizationId,
        returnUrl: `${getBaseUrl()}/organizations/${organizationId}/billing`,
      } as HandlingViewSubscriptionFlow,
      mutateApis: [],
    });
    return viewSubscriptionResponse;
  };

  return {
    viewSubscription,
    isViewSubscriptionLoading: isLoading || isMutatingData,
    viewSubscriptionError: error,
  };
};
