import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import {
  AddonActionNames,
  AddonLookupKey,
  HandlingUpdateSubscriptionFlow,
  UpdateSubscriptionSuccessResponse,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { getBaseUrl } from "@lib/client-side";
import { useCallback } from "react";

/**
 * Custom hook to manage the subscription update process.
 *
 * @param {EditDataOpt<UpdateSubscriptionSuccessResponse>} options - Configuration options for `useEditData_v2`.
 *
 * @returns {object} An object containing:
 *   - `updateSubscription` (function): A function to initiate the subscription update process.
 *       - Parameters:
 *         - `newAddon` (AddonItem<AddonLookupKey>): The new add-on to be added to the subscription.
 *       - Returns:
 *         - `Promise<UpdateSubscriptionSuccessResponse>`: The result of the subscription update process.
 *   - `isLoading` (boolean): Indicates whether the update process or associated mutation is in progress.
 *   - `error` (ClientError | null): The error returned by the useEditData_v2, if any.
 */
export const useUpdateSubscription = (
  options: EditDataOpt<UpdateSubscriptionSuccessResponse>
) => {
  const { data: session, hasData: availableSession } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();

  const { email = "", userId = "" } = availableSession
    ? {
        userId: session?.userId,
        email: organizationId
          ? session?.authorizations?.organizations[organizationId]?.ownerEmail
          : undefined,
      }
    : {};

  const { isLoading, isMutatingData, editData, error } =
    useEditData_v2<UpdateSubscriptionSuccessResponse>({
      ...options,
      stopGlobalError: options?.stopGlobalError ?? true,
    });

  const updateSubscription = useCallback(
    async ({
      newAddons,
    }: Pick<HandlingUpdateSubscriptionFlow, "newAddons">) => {
      // Search for an addon that represents a project upgrade, and if found, extract its projectId.
      // The search is performed using `.find()`, which iterates over `newAddons` and stops at the first match.
      // If an addon with `addon: PRO_PROJECT` and `action.name: UPGRADE` is found, its `projectId` is assigned to `projectId`.
      // If no such addon exists, `projectId` remains an empty string.
      let projectId: string = "";
      newAddons.find((addon) => {
        if (
          addon.addon === AddonLookupKey.PRO_PROJECT &&
          addon.action.name === AddonActionNames.UPGRADE
        ) {
          projectId = addon?.action.data?.projectId;
        }
      });

      const updateSubscriptionResponse = await editData({
        method: "PATCH",
        endpoint: `/api/billing/subscriptions/${email}`,
        endpointCallLocation: "useUpdateSubscription.js",
        serverType: "dashboard",
        body: {
          organizationId,
          email,
          userId,
          newAddons,
          successUrl: getBaseUrl(),
          cancelUrl: getBaseUrl(),
        } as HandlingUpdateSubscriptionFlow,
        mutateApis: [
          {
            url: `/api/organizations/${organizationId}/invitations`,
            component: "useGetPendingInvitations",
          },
          {
            url: `/api/organizations/${organizationId}/members`,
            component: "useGetMembers",
          },
          {
            url: `/api/organizations/${organizationId}/projects`,
            component: "useGetUserProjectsByOrganizationId",
          },
          { url: "/api/auth/session", component: "useGetUserSession" },
          ...(projectId
            ? [
                {
                  url: `/api/projects/${projectId}/jobs/nodes`,
                  component: "useGetProjectJobs",
                },
              ]
            : []),
        ],
      });
      return updateSubscriptionResponse;
    },
    [email, organizationId, userId, editData]
  );

  return {
    updateSubscription,
    isUpdatingSubscription: isLoading || isMutatingData,
    updateSubscriptionError: error,
  };
};
