//
// useCancelSubscription.ts
//

import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import {
  CancelSubscriptionSuccessResponse,
  HandlingCancelSubscriptionFlow,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { getBaseUrl } from "@lib/client-side";
import { useCallback } from "react";

/**
 * Custom hook to manage the subscription cancellation process.
 *
 * @param {EditDataOpt<CancelSubscriptionSuccessResponse>} options - Configuration options for `useEditData_v2`.
 *
 * @returns {object} An object containing:
 *   - `cancelSubscription` (function): A function to initiate the subscription cancellation process.
 *       - Returns:
 *         - `Promise<CancelSubscriptionSuccessResponse>`: The result of the subscription cancellation process.
 *   - `isLoading` (boolean): Indicates whether the cancellation process or associated mutation is in progress.
 */
export const useCancelSubscription = (
  options: EditDataOpt<CancelSubscriptionSuccessResponse>
) => {
  const { data: session } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();

  const { email = "", userId = "" } = {
    userId: session?.userId,
    email: organizationId
      ? session?.authorizations?.organizations[organizationId]?.ownerEmail
      : undefined,
  };

  const { isLoading, isMutatingData, editData, error } =
    useEditData_v2<CancelSubscriptionSuccessResponse>({
      ...options,
      successCallback: (data) => {
        options.successCallback?.(data);

        const stripeRedirectUrl = data.value.redirectUrl;
        if (stripeRedirectUrl) {
          window.location.href = stripeRedirectUrl;
        } else {
          console.error("Stripe redirect URL is not available.");
        }
      },
      stopGlobalError: options?.stopGlobalError ?? true,
    });

  const cancelSubscription = useCallback(
    async ({ deleteOrganization }: { deleteOrganization: boolean }) => {
      const cancelSubscriptionResponse = await editData({
        method: "DELETE",
        endpoint: `/api/billing/subscriptions/${email}`,
        endpointCallLocation: "useCancelSubscription.js",
        serverType: "dashboard",
        body: {
          successUrl: deleteOrganization
            ? getBaseUrl()
            : `${getBaseUrl()}/organizations/${organizationId}/billing`,
          cancelUrl: getBaseUrl(),
          organizationId,
          email,
          userId,
          deleteOrganization,
        } as HandlingCancelSubscriptionFlow,
        mutateApis: [
          {
            url: `/api/organizations/${organizationId}/projects`,
            component: "useGetUserProjectsByOrganizationId",
          },
          { url: "/api/auth/session", component: "useGetUserSession" },
        ],
      });

      return cancelSubscriptionResponse;
    },
    [email, organizationId, userId, editData]
  );

  return {
    cancelSubscription,
    isCancellingSubscription: isLoading || isMutatingData,
    cancelSubscriptionError: error,
  };
};
