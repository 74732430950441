//
// useRunListInfo.ts
//

import { useRunSQLQuery } from "@custom-hooks/weblite";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { ListInfo } from "@data-types/sqlitecloud-commands-type";
import { SWRConfiguration } from "swr";

export function useRunListInfo(
  projectId: string,
  options?: SWRConfiguration
): FetchHookResult<ListInfo> {
  return useRunSQLQuery({
    projectId,
    query: "LIST INFO",
    options,
  });
}
